const pathList = {};

pathList.root = "/";

pathList.login = "/login";
pathList.portal = "/portal";

pathList.configuration = "/configuration";

pathList.retrospective = "/retrospective";
pathList.logfiles = `${pathList.retrospective}/logfiles`;
pathList.eventTable = `${pathList.retrospective}/events`;
pathList.eventDownload = (eventId = ":eventId") =>
  `${pathList.eventTable}/download/${eventId}`;
pathList.eventView = (eventId = ":eventId") =>
  `${pathList.eventTable}/view/${eventId}`;

pathList.recruits = `${pathList.configuration}/recruits`;
pathList.recruitUpdate = (recruitId = ":recruitId") =>
  `${pathList.recruits}/update/${recruitId}`;
pathList.recruitAdd = `${pathList.recruits}/add`;

pathList.devices = `${pathList.configuration}/devices`;
pathList.deviceUpdate = (deviceId = ":deviceId") =>
  `${pathList.devices}/update/${deviceId}`;
pathList.deviceCreate = `${pathList.devices}/create`;

pathList.commHubs = `${pathList.configuration}/commHubs`;
pathList.commHubUpdate = (mac = ":mac") => `${pathList.commHubs}/commHubDetails/${mac}`;

pathList.events = `${pathList.configuration}/events`;
pathList.eventUpdate = (eventId = ":eventId") =>
  `${pathList.events}/update/${eventId}`;
pathList.eventCreate = `${pathList.events}/create`;

pathList.pair = `${pathList.configuration}/pair`;

pathList.pois = `${pathList.configuration}/pois`;
pathList.poiCreate = `${pathList.pois}/create`;
pathList.poiUpdate = (poiId = ":poiId") => `${pathList.pois}/update/${poiId}`;

pathList.squads = `${pathList.configuration}/squads`;
pathList.squadCreate = `${pathList.squads}/create`;
pathList.squadUpdate = (squadId = ":squadId") =>
  `${pathList.squads}/update/${squadId}`;

pathList.realTime = "/real-time";

pathList.realTimeStatus = "/rts";

pathList.realTimeDetails = "/rtd";

Object.freeze(pathList);

export default pathList;
