import {useState, useEffect, useRef} from "react";

import lodash from "lodash";

import {connectIOSocket} from "../../util/sails-socket";
import {getUser} from "../../actions/user-actions";
import {
    getTrackedDevices,
    joinLiveCache,
    joinLiveTracking,
    registerNewDataListener,
    registerLiveTrackingDataListener,
    getLiveCache,
} from "../../actions/real-time-actions";

import Button from "../misc/Button";
import NitricOxideGraph from "../real-time/graphs/NitricOxideGraph";
import TemperatureSkinGraph from "../real-time/graphs/TemperatureSkinGraph";

// Variants of mu in HTML
// <p>I will display &#956;</p>
// <p>I will display &#x3BC;</p>
// <p>I will display &mu;</p>
const nitricOxideUnit = '\u03BCM';

/*--------------------------------------------------------------------------------*/

function capKey(value) {
    return lodash.startCase(value);
}

async function sleep(time) {
    return new Promise((resolve, _) => {
        setTimeout(() => resolve(), time);
    })
}

async function registerSocket(socketRef, dataCallback, {eventId, onDisconnect, timeout} = {}) {
    console.info('registerSocket timeout', timeout)
    return connectIOSocket({ timeout }).then((socket_, _io) => {
        console.log("RT: Socket Connected");
        socketRef.current = socket_;
        return joinLiveCache(socket_, {eventId});
    }).then((msg) => {
        console.log("RT: Joining Live Cache:", msg);
        return registerNewDataListener(socketRef.current, dataCallback);
    }).then((ret) => {
        socketRef.current.on('disconnect', () => onDisconnect());
        return ret;
    }).catch(err => {
        console.error('RT:', err);
        throw err;
    });
}

/*--------------------------------------------------------------------------------*/

function StatusField(props = {}) {
    const {
        label = 'Label',
        value = '',
    } = props;

    const style = {
        paddingRight: '1em',
        minWidth: "10em",
    }

    return (
        <div className="field has-addons">
            <div className="label" style={style}>{label}:</div> {value}
        </div>
    )
}


function maybeRound(value, prec) {
    if (value == null) {
        return value;
    }
    return value.toFixed(prec);
}


/**
 * Return a more valid value to be displayed through React.
 */
function handleValue(value) {
    if (lodash.isNumber(value)) {}
    else if (lodash.isString(value)) {}
    else if (value == null) {
        value = '<NULL>';
    }
    else {
        // value = '<Unhandled value>';
        // value = lodash.toString(value);
        value = JSON.stringify(value);
    }
    return value;
}


function TableOfData(props = {}) {
    const {
        key='blah',
        data={},
        units={},
    } = props;

    const cellStyle = {
        paddingLeft: '0.5em',
        paddingRight: '0.5em',
    };

    const cells = Object.entries(data).map(([k, v]) => (
        <tr key={`${key}-${k}`}>
            <th style={cellStyle}>{capKey(k)}</th>
            <td style={cellStyle}>{handleValue(v)}{(units[k] == null) ? '' : ' '}{units[k] ?? ''}</td>
        </tr>
    ));

    return (
        <table key={key} style={{padding: '.25em'}}>
            <tbody>
                {cells}
            </tbody>
        </table>
    )
}


function StatusExtraInfo(props = {}) {
    const {
        key = 'blah',
        data = {},
    } = props;

    return (
        <div key={key + '-extras'}>
            <TableOfData key={key + '-extras'} data={data.status ?? {}} />
        </div>
    );
}


function StatusBlock(props = {}) {
    const {
        key = 'blah',
        data = {},
        extraStatus = false,
        onSelect = (devId) => {},
    } = props;

    const style = {
        border: '1px solid',
        padding: '1em'
    };
    const cellStyle = {
        paddingLeft: '0.5em',
        paddingRight: '0.5em',
    };

    const dataItems = Object.fromEntries(Object.entries({
        "NO (1)": [maybeRound(data.no?.[0], 6), nitricOxideUnit],
        "NO (2)": [maybeRound(data.no?.[1], 6), nitricOxideUnit],
        "NO (3)": [maybeRound(data.no?.[2], 6), nitricOxideUnit],
        "Temp (skin)": [maybeRound(data.stemp, 6), 'F'],
        "HR": [data.hr, 'bpm'],
        "Battery": [maybeRound(data.status?.battery, 2), '%'],
    }).filter(([_, v]) => v[0] != null));

    const units = {
        "NO (1)": nitricOxideUnit,
        "NO (2)": nitricOxideUnit,
        "NO (3)": nitricOxideUnit,
        "Temp (skin)": 'F',
        "HR": 'bpm',
        "Battery": '%',
    };

    const renderData = {
        "Roster Id": data.rosterId,
        Device: data.macAddress,
        "Active": data.activeStatus,
        "Device Type": data.deviceType,
        "Last Packet Recv": new Date(data.lastDataTimestamp).toLocaleString(),
    };

    let extras = (extraStatus && (data.status != null)) ? (
        <StatusExtraInfo data={data} key={key} />
    ) : null;

    return (
        <div key={key} className="box" style={style} onClick={(e) => onSelect(data.deviceId)}>
            <div className="columns">
                <div className="column">
                    <TableOfData
                        key={key + '-data'}
                        data={renderData}
                        units={units}
                    />
                </div>
                <div className="column">
                    <TableOfData
                        key={key + '-data'}
                        data={Object.fromEntries(
                            Object.entries(dataItems).map(([k, v]) => [k, v[0]])
                        )}
                        units={units}
                    />
                </div>
            </div>
            { extras != null ? <hr/> : null }
            { extras }
        </div>
    );
}

function GraphsView(props = {}) {
    const {
        deviceId = '#',
        timeWidthMs = 600000, // 10 min
        statusData = {},
        graphData = {},
        onClose = () => {},
    } = props;

    const devStatus = statusData[deviceId] ?? {};
    const devGraphData = graphData[deviceId] ?? {};

    const graphStyle = {
        position: "relative",
    };

    return (
        <div className="box">
            <div className="level">
                <div className="level-left"> </div>
                <div className="level-item title">Device {devStatus.rosterId}</div>
                <div className="level-right">
                    <div className="level-item delete is-danger" onClick={() => onClose()}>Close</div>
                </div>
            </div>

            <div class="chart-container" style={graphStyle}>
                <div className="level"><div className="level-item title is-4">Nitric Oxide (&mu;M)</div></div>
                <NitricOxideGraph data={devGraphData.no} timeWidth={timeWidthMs} showXLabels={true} />
            </div>
        </div>
    );
}


/*--------------------------------------------------------------------------------*/

export default function RealTimeDetails(props = {}) {
    const defaultTimeWidth = 600000; // 10 min
    const timeWidthMs = defaultTimeWidth;

    const socket = useRef(null);
    const allowReconnect = useRef(true);

    /** Raw data payload received by socket. */
    const [dataPl, setDataPl] = useState({devices: {allIds: [], byId: {}}});
    /** Status data for non-array info on devices. Key is device id; value is device status data. */
    const [statusData, setStatusData] = useState({});
    /**
     * Graph data for array info on devices. The form resembles.
     *
     *      {
     *        "ABCDEF123456": {
     *          "no": [
     *            {
     *              "timestamp": 1714070341000,
     *              "timeOfReceipt": 1714070340842,
     *              "value": [15.075, 15.067, 15.0960]
     *            },
     *            ...
     *          ],
     *          ... <more metrics> ...
     *        },
     *        ... <more devices> ...
     *      }
     */
    const [graphData, setGraphData] = useState({});
    const [realGraphData, setRealGraphData] = useState({});
    /** Status blocks to be rendered */
    const [renderData, setRenderData] = useState([]);
    /** Used to periodically trigger a query for active devices. */
    const [idQueryTrigger, setIdQueryTrigger] = useState(0);
    /** Device status, where the key is the deviceId and value is from getTrackedDevices. */
    const [deviceActivity, setDeviceActivity] = useState(0);
    /** . */
    const [selectedDevice, setSelectedDevice] = useState(null);
    /** . */
    const [trigger, setTrigger] = useState(0);
    /** . */
    const [pauseGraph, setPauseGraph] = useState(false);
    const [graphView, setGraphView] = useState(null);
    const [extraStatus, setExtraStatus] = useState(false);

    /*----------------------------------------*/
    /* Local functions
     */

    const connectSocket = async ({callback, eventId, timeout} = {}) => {
        const sock = socket;
        const onDisconnect = () => {
            console.warn('Socket onDisconnect called!');
            if (sock.current?.close) {
                console.info('Close flag detected');
                sock.current.closed = true;
                return;
            }
            if (allowReconnect.current) {
                setTimeout(() => {
                    console.info('Attempting to reconnect to detached socket...');
                    connectSocket({callback, eventId, timeout: 5000}).then(() => {
                        console.info('Reconnected to socket');
                    }).catch(err => {
                        console.info('Reconnect failed, trying in another 5 sec...');
                        onDisconnect();
                    })
                }, [5000]);
            }
        };
        if (eventId == null) {
            getLiveCache(1).then(data => {
                setDataPl(data);
            }).catch(error => console.error(error));
        }
        const tmpRefObj = {};
        return registerSocket(
            tmpRefObj,
            setDataPl,
            {eventId, onDisconnect, timeout}
        ).then(() => {
            socket.current = {
                sock: tmpRefObj.current,
                onDisconnect,
                close: false,
                closed: false,
            }
        });
    };

    const disconnectSocket = async () => {
        const sock = socket;
        console.info('Disconnect called: Socket status', sock.current);
        if ((sock.current != null) && (sock.current.sock.isConnected())) {
            console.info('Forcing socket disconnection');
            sock.current.close = true;
            sock.current.sock.off('disconnect', sock.current.onDisconnect);
            sock.current.sock.disconnect();
            while (sock.current.closed === false) {
                sleep(100);
            }
            sock.current = null;
            console.info('Done closing socket')
        }
    };

    /*----------------------------------------*/
    /* Effects (Triggers on data change)
     */

    /** On Startup and Exit. */
    useEffect(() => {
        connectSocket();
        return () => {
            allowReconnect.current = false;
            return Promise.all([
                disconnectSocket(),
            ]);
        };
    }, []);

    /** When data is received from the socket. */
    useEffect(() => {
        console.debug('New data pl', dataPl);
        const oldestTime = Date.now() - Math.max(defaultTimeWidth, timeWidthMs);
        Object.entries(dataPl.devices.byId).forEach(([devid, devdata]) => {
            statusData[devid] = {
                ...devdata.geoJSON.properties,
                lastDataTimestamp: devdata.lastDataTimestamp,
                activeStatus: deviceActivity[devid]?.status ?? "missing",
            };
            Object.entries(devdata.data).forEach(([m, mdata]) => {
                if (!(devid in graphData)) {
                    graphData[devid] = {};
                }
                if (!(m in graphData[devid])) {
                    graphData[devid][m] = [];
                }
                const maxTs = (
                    (graphData[devid][m].length === 0)
                    ? 0
                    : Math.max(...graphData[devid][m].map((x) => x.timestamp))
                );
                // Join the data while also discarding very old data
                graphData[devid][m] = [].concat(
                    graphData[devid][m].filter((x) => x.timestamp > oldestTime),
                    mdata.filter((x) => (x.timestamp >= oldestTime) && (x.timestamp > maxTs))
                );
            });
        })
        setStatusData(statusData);
        setGraphData(graphData);
        setTrigger(Date.now());
    }, [dataPl]);

    /** After the first handling of the RT data, this will form the visible blocks. */
    useEffect(() => {
        setRenderData(
            Object.values(statusData)
            .filter((x) => x.activeStatus != "missing")
            .map((x) => (
                <StatusBlock
                    key={x.deviceId}
                    data={x}
                    onSelect={setSelectedDevice}
                    timeWidthMs={timeWidthMs}
                    extraStatus={extraStatus}
                />
            ))
        );
    }, [statusData, trigger, extraStatus]);

    /** Enable pausing the graph by putting a man in the middle of the data updates and
     * the data sent to the graphs. */
    useEffect(() => {
        if (pauseGraph === false) {
            setRealGraphData(JSON.parse(JSON.stringify(graphData)));
        }
    }, [graphData, pauseGraph, trigger]);

    /** Only update the graph if the data changes. */
    useEffect(() => {
        if (selectedDevice == null) {
            setGraphView(null);
        }
        else {
            setGraphView(
                    <GraphsView
                        deviceId={selectedDevice}
                        statusData={statusData}
                        graphData={realGraphData}
                        onClose={() => setSelectedDevice(null)}
                    />
            );
        }
    }, [realGraphData, selectedDevice]);

    /** Periodic querying of tracked (active) devices for the removing of blocks logic. */
    useEffect(() => {
        const user = getUser();
        getTrackedDevices(user.id, {outputVersion: '2'}).then((rawDeviceIds) => {
            console.debug('Got tracked devices:', rawDeviceIds);
            const tmp = Object.fromEntries(
                Object.entries(rawDeviceIds)
                .map(([k, v]) => ([k.replace(/:/g, ''), v]))
            );
            console.debug('Track device dict', tmp);
            setDeviceActivity(tmp);
            setTrigger(Date.now());
        }).catch((err) => {
            console.error('Failed to get device statuses', err.message);
        }).finally(() => {
            setTimeout(() => setIdQueryTrigger(Date.now()), 3000)
        });
    }, [idQueryTrigger]);

    useEffect(() => {
        console.info("Pause graph status", pauseGraph);
    }, [pauseGraph]);

    /*----------------------------------------*/
    /* Render Area
     */

    return (
        <div className="container">
            <div className="level">
                <div className="level-item title">
                    Real-Time Details
                </div>
            </div>
            <div className="level">
                <div className="level-left"></div>
                <div className="level-right">
                    <div className="level-item">
                        <Button type="checkable" activeColor="is-hraps" onClick={(v) => setExtraStatus(v)}>
                            Extra Status
                        </Button>
                    </div>
                    <div className="level-item">
                        <Button type="checkable" activeColor="is-hraps" onClick={(v) => setPauseGraph(v)}>
                            Pause Graph
                        </Button>
                    </div>
                </div>
            </div>

            <div className="block"></div>

            {
                (selectedDevice == null)
                ? (
                    renderData
                )
                : (
                    graphView
                )
            }
        </div>
    );
}
