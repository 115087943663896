import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import "./style/main.scss";

import PathRouter from "./components/misc/PathRouter";

import Login from "./components/login/Login";
import Logfile from "./components/logfile/LogFile";
import ConfigurationRouter from "./components/configuration/ConfigurationRouter";
import DashboardPortal from "./components/portal/DashboardPortal";
import RealTime from "./components/real-time/RealTime";
import RealTimeDetails from "./components/rt-details/RealTimeDetails";
import RetrospectiveRouter from "./components/retrospective/RetrospectiveRouter";
import RtsEventSelection from "./components/rt-status/RtsEventSelection";

import PathList from "./util/path-list";

export default class App extends React.Component {
  static defaultProps = {
    routes: [
      {
        component: <DashboardPortal />,
        route: PathList.portal,
        protected: true,
      },
      {
        component: <ConfigurationRouter />,
        route: PathList.configuration,
        protected: true,
      },
      {
        component: <RealTime />,
        route: PathList.realTime,
        protected: true,
      },
      {
        component: <RealTimeDetails />,
        route: PathList.realTimeDetails,
        protected: true,
      },
      {
        component: <RtsEventSelection />,
        route: PathList.realTimeStatus,
        protected: true,
      },
      {
        component: <RetrospectiveRouter />,
        route: PathList.retrospective,
        protected: true,
      },
      {
        component: <Login />,
        route: PathList.login,
      },
    ],
  };

  render() {
    return (
      <div>
        <Router>
          <PathRouter routes={this.props.routes} redirect={PathList.login} />
        </Router>
      </div>
    );
  }
}
