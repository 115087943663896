import React, { useState, useEffect } from "react";
import ReactTooltip from 'react-tooltip';

import HeartRateGraph from "./graphs/HeartRateGraph";
import HeatStressIndexGraph from "./graphs/HeatStressIndexGraph";
import TemperatureCoreGraph from "./graphs/TemperatureCoreGraph";
import TemperatureSkinGraph from "./graphs/TemperatureSkinGraph";
import NitricOxideGraph from "./graphs/NitricOxideGraph";

import {getLastArrayValue, insertMissingMACAddressColons} from "../../util/format-util";
import {MISSING_DATA_STRING} from "./real-time-constants";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import {ENV} from "../../util/env-util";

import Button from '../misc/Button';

/**
 * Creates the checkbox with info tooltip for tracking a device on the map.
 */
function createFollowDeviceBlock(tracking, setTracking) {
    return (
        <div className={"field is-horizontal"}>
            <div className={"field-label columns"}>
                <FontAwesomeIcon icon={faInfoCircle} data-tip data-for='tracking' className={'mt-1 ml-3'}/>
                <ReactTooltip id='tracking' type='info'>
                    <p>Follow This Device And Lock Map Movement</p>
                </ReactTooltip>
                <label className={"label ml-1"}>Follow Unit
                    <input
                        className={"checkbox ml-1"}
                        name="tracking"
                        type="checkbox"
                        checked={tracking}
                        onChange={() => {setTracking(!tracking)}}/>
                </label>
            </div>
        </div>
    );
}

/**
 * Creates the device detail info block for the sidebar.
 *
 * If the device type is "womo" then the visible graphs will be changed to the device specific
 * types rather than the classic set.
 */
function createDataView(deviceData, options = {}) {
    const {
        debug_flag = false,
        tracking = false,
        setTracking = ((_v) => {}),
        celsius = false,
    } = options;

    const coords = getLastArrayValue(deviceData?.data?.latlon);
    const deviceId = (!!deviceData?.nativeDeviceId)
        ? deviceData.nativeDeviceId
        : insertMissingMACAddressColons(deviceData?.id);
    const deviceType = deviceData?.deviceType;
    console.debug(`RTSBDD: Using ${(!!deviceData?.nativeDeviceId) ? 'nativeDeviceId' : 'id'}`);

    const deviceInfo = [{
        id: 'device_id_div',
        label: 'Device ID',
        value: deviceId,
        alwaysVisible: true,
    }, {
        id: "roster_id_div",
        label: 'Roster ID',
        value: deviceData?.rosterId,
        alwaysVisible: true,
    }, {
        id: "risk_id_div",
        label: 'Risk',
        value: deviceData?.risk,
        alwaysVisible: true,
    }, {
        id: "gps_coordinates",
        label: 'Location',
        value: `[${coords?.[0]?.toFixed(4) ?? MISSING_DATA_STRING}, ${coords?.[1]?.toFixed(4) ?? MISSING_DATA_STRING}]`,
        alwaysVisible: true,
    }, {
        id: "heart_rate_div",
        label: 'HR',
        value: getLastArrayValue(deviceData?.data?.hr),
        alwaysVisible: false,
    }, {
        id: "hsi_div",
        label: 'HSI',
        value: getLastArrayValue(deviceData?.data?.hsi),
        alwaysVisible: false,
    }, {
        id: "ctemp_div",
        label: 'ctemp',
        value: getLastArrayValue(deviceData?.data?.ctemp),
        alwaysVisible: false,
    }, {
        id: "stemp_div",
        label: 'stemp',
        value: getLastArrayValue(deviceData?.data?.stemp),
        alwaysVisible: false,
    }];

    const deviceInfoBlock = deviceInfo.filter((v) => (
        v.alwaysVisible || debug_flag
    )).map((v) => (
        <div id={debug_flag ? v.id : undefined} key={`${v.id}-${Date.now()}`}>
            {v.label}: {v.value}
        </div>
    ));

    const trackingBlock = createFollowDeviceBlock(tracking, setTracking);

    return (
        <div>
            {trackingBlock}

            {deviceInfoBlock}

            {
                (deviceType === "womo")
                ? (<>
                    <div>
                        <NitricOxideGraph data={deviceData.data?.no} />
                    </div>

                    <div>
                        <TemperatureSkinGraph data={deviceData.data?.stemp} celsius={celsius} />
                    </div>
                </>)
                : (<>
                    <div>
                        <HeartRateGraph data={deviceData.data?.hr} />
                    </div>

                    <div>
                        <HeatStressIndexGraph data={deviceData.data?.hsi} />
                    </div>

                    <div>
                        <TemperatureCoreGraph data={deviceData.data?.ctemp} celsius={celsius} />
                    </div>

                    <div>
                        <TemperatureSkinGraph data={deviceData.data?.stemp} celsius={celsius} />
                    </div>
                </>)
            }
        </div>
    );
}

/**
 * Component for displaying device data for a single device.
 *
 * @param props {Object}        React properties.
 *      - deviceDetailData {Object}     Data for the device in the form of arrays for each metric as
 *                                      well as the `rosterId`, and `id`.
 *      - celsius {Bool}                To use Celsius or false for Fahrenheit.
 *      - map {MapboxGL}                Map object from mapboxgl.
 *      - clickReturnButton {Callable}  Callback for when user clicks to return from this screen.
 */
export default function RealTimeSidebarDeviceDetail(props) {
    const deviceDetailData = props.deviceDetailData || {};
    const clickReturnButton = props.clickReturnButton || (() => {});
    const celsius = props.celsius || false;
    const map = props.map || null;

    const [tracking, setTracking] = useState(false);
    const [prevDeviceId, setPrevDeviceId] = useState(deviceDetailData?.id);

    const debug_flag = ENV.webClientDebug === "1"
    const deviceId = deviceDetailData?.id;
    const currentCoords = getLastArrayValue(deviceDetailData?.data?.latlon);

    /* Events */

    useEffect(() => {
        return () => {
            // This replaces componentWillUnmount()

            // Ensure dragging is enabled again.
            map?.dragPan.enable();
        };
    }, []);

    useEffect(() => {
        if (tracking && currentCoords && (deviceId === prevDeviceId)) {
            map?.dragPan.disable();
            map?.flyTo({center: [currentCoords[1], currentCoords[0]]});
        }
    }, [currentCoords, tracking]);

    useEffect(() => {
        if (!tracking) {
            map?.dragPan.enable();
        }
    }, [tracking]);

    useEffect(() => {
        if (deviceId !== prevDeviceId) {
            setTracking(false);
        }
        setPrevDeviceId(deviceId);
    }, [deviceId]);

    /* Returned */

    const contentOpts = {
        debug_flag,
        tracking, setTracking,
        celsius,
    }
    const content = (deviceDetailData?.id)
        ? createDataView(deviceDetailData, contentOpts)
        : "No Device Data Found.";

    return (
        <div>
            <div className="level">
                <Button
                    classList={['level-item']}
                    onClick={clickReturnButton}
                >Show All Devices</Button>
            </div>
            <div className="level">
                <div className="level-item">
                    {content}
                </div>
            </div>
        </div>
    );
}
