import React, {useEffect} from "react";
import { useState } from 'react';
import { Link } from "react-router-dom";

import IconText from "./IconText";
import {getEventStatus} from "../../actions/event-actions";
import {getUser} from "../../actions/user-actions";

export default function QuickDashboard(props) {
    const [eventStatus, setEventStatus] = useState(null);
    const [userIsAdmin, setUserIsAdmin] = useState(false);
    useEffect(() => {
        getEventStatus("").then((resp) => {
            setEventStatus(resp);
        })
        const user = getUser();
        if (user.firstName === "admin") {
            setUserIsAdmin(true);
        }
    }, [])

    const isLinkDisabled = (title) => {
        if (title === "Hot-Swap Devices" && eventStatus && eventStatus.length === 0) {
            return {
                pointerEvents: "none",
                opacity: "50%"
            }
        } else if (title === "Comm Hubs" && !userIsAdmin) {
            return {
                pointerEvents: "none",
                opacity: "50%"
            }
        }
        return {}
    }
    const dashboardClass = "column has-text-centered"
    const configClass = "column has-text-centered is-three-fifths"
    const dashboardButtons = ["Real-Time", "Hot-Swap Devices", "Retrospective", "Configuration"]

    const linkButtons = props.links?.filter((x) => ((x.hidden ?? false) == false))?.map((v, i) => {
        console.log(v.title);
        return (
            <div className={dashboardButtons.includes(v.title) ? dashboardClass : configClass} key={`portal-link-${v.title}-${i}`}>
                <Link to={v.destination} style={isLinkDisabled(v.title)}>
                    <div className="card purple-border">
                        {v?.icon ? <IconText icon={v.icon} text={v.title} iconTextClasses={["is-size-4", "p-2"]} /> : <span className="is-size-4">{v.title}</span>}
                    </div>
                </Link>
            </div>
        )
    });

    return (
        <div className="container">
            <div className="level">
                <h1 className="level-item title">{props.title}</h1>
            </div>

            <div className="columns is-centered is-multiline is-fullwidth">
                {linkButtons}
            </div>
        </div>
    )
}
