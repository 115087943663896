import DataChart from "../../misc/DataChart";
import {celsiusToFahrenheit, getTimestampInLocalTime} from "../../../util/format-util";
import {
    MISSING_DATA_STRING,
    REALTIME_INACTIVE_TIME_MS,
    DEG_C,
    DEG_F,
    TIMESTAMP_KEY,
} from "../real-time-constants";

/**
 * Filters the data to the desired time window and convert if conversion function was provided.
 *
 * @param metricData {Array}        Array of samples as `{timestamp: ..., value: ...}`.
 * @param convFunction {Callable}   [Optional] Convert a value while being processed, e.g. for
 *                                  C->F translation.
 *
 * @returns {Array}  Of samples for the chart as `{x: <timestamp>, y: <value>}`.
 */
function filterData(metricData, convFunction, {timeWidth = null} = {}) {
    const now = Date.now();
    const offset = timeWidth ?? REALTIME_INACTIVE_TIME_MS;
    const timeRange = [now - offset, now + offset];

    let processed = metricData || [];
    let lastData = processed[processed?.length-1] ? [processed[processed?.length-1]] : [];
    processed = processed.filter((s) => (
        (typeof(s) === 'object') && (s[TIMESTAMP_KEY] > timeRange[0]) && (s[TIMESTAMP_KEY] < timeRange[1]))
    );
    processed = [].concat(processed, lastData || []);
    processed = processed.map(convFunction ?? ((v) => v)).sort((a, b) => (
        a[TIMESTAMP_KEY] > b[TIMESTAMP_KEY]
    )).map((v) => {
        return {x: new Date(v[TIMESTAMP_KEY]), y: v.value}
    });

    const last = processed[processed.length - 1];

    const min = {x: now - offset, y: null};
    const max = {x: (last?.x > now) ? last.x : now, y: null};

    processed = [min, ...processed, ...((max.x === last?.x) ? [] : [max])];

    return [processed, last || {x: 0, y: null}];
}

/**
 * To support the Nitric Oxide (NO) data which is formatted as an array of 3 elements (floats). This
 * will convert that to a dictionary with "numbered" keys which can be referenced by Chart.JS from
 * three separate dataset defitinions.
 *
 *      datasets: [
 *          {
 *              label: "NO (1)",
 *              data: data,
 *              parsing: {yAxisKey: 'y.0'},
 *          },
 *          {
 *              label: "NO (1)",
 *              data: data,
 *              parsing: {yAxisKey: 'y.1'},
 *          }
 *      ]
 *
 * @note On the example, later "time" is changed to "x" and value to "y".
 */
function nitricOxideDataPacket(pkt) {
    return {...pkt, value: {'1': pkt.value[0], '2': pkt.value[1], '3': pkt.value[2]}};
}


/**
 *  This should come after processing of input data.
 *
 * @returns {Object}
 *      - name
 *      - metric
 *      - timestamp
 *      - value
 *      - datasetExtras: Extra defitinions on datasets (_allows to have multiple lines_).
 */
function getMetricMeta(metricType, lastSample, celsius) {
    const meta =  {
        hr: { name: "Heart Rate", metric: " BPM" },
        ctemp: { name: "Core Temperature", metric: celsius ? DEG_C : DEG_F },
        stemp: { name: "Skin Temperature", metric: celsius ? DEG_C : DEG_F },
        hsi: { name: "HSI", },
        no: {
            name: "NO",
            lastValueCb: (v) => (v['1']).toFixed(6),
            datasetExtras: [
                {label: 'NO (1)', parsing: {yAxisKey: 'y.0'}},
                {label: 'NO (2)', parsing: {yAxisKey: 'y.1'}},
                {label: 'NO (3)', parsing: {yAxisKey: 'y.2'}},
            ],
            metric: '\u03BCM',
        },
        default: { name: "Value", metric: "", datasetExtras: [{}], lastValueCb: null },
    }
    const fields = ['name', 'metric', 'datasetExtras', 'lastValueCb'];
    const ret = Object.fromEntries(fields.map((k) => [k, meta[metricType][k] || meta.default[k]]));
    return {
        ...ret,
        timestamp: lastSample.x ?? MISSING_DATA_STRING,
        value: lastSample.y ?? MISSING_DATA_STRING,
    }
}

/**
 * @param props {Object}    Properties passed into this component.
 *
 * returns {DOM} Object to be displayed.
 */
export default function DeviceDetailGraph(props) {
    // NOTE data initially seems to be a value used for the table in the previous view. This then
    // changes to an Array for the graph.
    const data = (props.data instanceof Array) ? props.data : [];
    const dataType = props.dataType ?? '';
    const celsius = props.celsius ?? true;
    const label = props.label ?? 'graph';
    const backgroundColor = props.backgroundColor ?? 'rgba(255, 99, 132, 0.2)';
    const borderColor = props.borderColor ?? 'rgba(255, 99, 132, 0.2)';
    const borderWidth = props.borderWidth ?? 1;
    const timeWidth = props.timeWidth;
    const graphHeight = props.graphHeight;
    const showXLabels = props.showXLabels ?? false;

    let converter = undefined;
    if (((["ctemp", "stemp"].includes(dataType)) && (!celsius))) {
        converter = ((v) => ({...v, value: celsiusToFahrenheit(v.value)}));
    }
    else if (["no"].includes(dataType)) {
        converter = nitricOxideDataPacket;
    }
    const [processedData, lastSample] = filterData(data, converter, { timeWidth });
    const meta = getMetricMeta(dataType, lastSample, celsius);

    const time = processedData.map(({x}) => x);

    // Enable some metrics to have multiple data lines
    const datasets = meta.datasetExtras.map((x) => ({
        label: label,
        data: processedData,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        borderWidth: borderWidth,
        radius: 0,
        order: 1,
        ...x,
    }));

    const chartConfig = {
        type: 'line',
        options: {
            responsive: true,
            hover: { mode: 'nearest', intersect: false },
            plugins: { legend: { position: 'top', onClick: () => {return null;} } },
            scales: { x: { type: 'time', display: showXLabels } },
            // aspectRatio: 1,
        },
        data: {
            labels: time,
            datasets: [
                ...datasets,
                {
                    label: `Last Timestamp: ${getTimestampInLocalTime(lastSample.x) ?? MISSING_DATA_STRING}`,
                    backgroundColor: '#ffffff',
                    borderColor: '#ffffff',
                    order: 2,
                },
                {
                    label: `Last ${meta.name}: ${(meta.lastValueCb == null) ? meta.value : meta.lastValueCb(meta.value)}${meta.metric}` ,
                    backgroundColor: '#ffffff',
                    borderColor: '#ffffff',
                    order: 3,
                },
            ],
        },
    };
    console.debug('chartConfig', chartConfig);

    return (<DataChart config={chartConfig} />);
}
