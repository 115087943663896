import {
    REALTIME_INACTIVE_TIME_MS,
    NO_DEVICE_SELECTED,
    SELECTED_ICON_SIZE,
    DEFAULT_ICON_SIZE,
    riskToColor,
    SINCE_LAST_UPDATE_BUFFER,
    NOT_SELECTED_ICON_OPACITY,
} from "./real-time-constants";
import {
    MAP_IMAGES,
} from "./map-utils";

import {getLastArrayValue} from "../../util/format-util";

import lodash from "lodash";

/**
 * Perform recursive merging of an object with another object.
 *
 * @param obj1 {Object}
 *      First object to be merged in, this will be overwritten by the next.
 * @param obj2 {Object}
 *      Second object to be merged in.
 * @param arrayReplace {Boolean}
 *      [Optional] Fully replaces arrays instead of concat (needed for coordinates).
 *
 * @returns {Object}    Merged object as a product of the two inputs.
 */
function nestedObjectMerge(obj1, obj2, {arrayReplace = false} = {}) {
    function doGet(obj, prev) {
        const new_ = {};
        Object.entries(obj).forEach(([k, v]) => {
            if (v === '' && (prev?.[k] !== undefined)) {
                return;
            }
            if (v instanceof Array && !arrayReplace) {
                let prevA = [];
                if (k in ret) {
                    prevA = prev[k];
                }
                new_[k] = [].concat(prevA, v);
            }
            else if (v instanceof Array && arrayReplace) {
                if (v.length > 0) {
                    new_[k] = v;
                }
            }
            else if (v instanceof Object) {
                new_[k] = doGet(v, prev?.[k])
            }
            else {
                new_[k] = v;
            }
        });
        return new_
    }

    let ret = obj1;
    ret = doGet(obj2 || {}, ret);
    return ret;
}


function parseOutPointsOfInterest(dataInObj) {
    const poiSrcData = dataInObj?.pois?.byId ?? {};
    const ret = Object.entries(poiSrcData).map(([poiId, poiData]) => {
        const ret = {
            ...poiData,
            isPoi: true,
            id: poiId,
            deviceType: poiData.poiType,
            deviceId: poiId,
            rosterId: poiData.friendlyName,
            geoJSON: {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [poiData.lastLongitude, poiData.lastLatitude],
                },
                properties: {
                    deviceId: poiId,
                    rosterId: poiData.friendlyName,
                    deviceType: poiData.poiType,
                    riskColor: '#00F'
                },
            },
        };
        return [poiId, ret];
    });
    return Object.fromEntries(ret);
}


/**
 * Accepts a data reference and updates it with new data provided while filtering, sorting, and
 * pruning as needed.
 */
export function updateDeviceData(data, dataRef, activeAndInactiveDevs) {
    const previousData = dataRef.current;
    if (data.length === 0 || !data.devices) {
        return previousData ?? {devices: {allIds: [], byId: {}}};
    }

    const activeDeviceIds = Object.keys(activeAndInactiveDevs ?? {});

    const newDevices = Object.entries(data.devices.byId)
        .filter(([k, _]) => (activeDeviceIds.includes(k)));
    const oldDevices = Object.entries(previousData?.devices?.byId ?? {})
        .filter(([k, _]) => (activeDeviceIds.includes(k)));

    const combined = Object.fromEntries([].concat(oldDevices, newDevices));

    const newData = {
        devices: {
            allIds: Object.keys(combined),
            byId: combined,
        }
    };
    console.debug('newData:', newData);

    if (data.pois?.allIds?.length > 0) {
        const tmpPoi = parseOutPointsOfInterest(data);
        newData.pois = {
            allIds: Object.keys(tmpPoi),
            byId: tmpPoi,
        };
    }

    return newData;
}

export function transformDeviceDataGeoJSON(data, deviceStatuses, selectedDevice) {
    let ids = data.devices.allIds;
    const deviceIds = Object.keys(deviceStatuses);

    if (deviceIds?.length > 0) {
        ids = deviceIds; // If deviceIds are provided, filter data to only
                         // use the provided ids.
    }
    if (data.pois?.allIds?.length > 0) {
        ids = [].concat(ids, data.pois.allIds)
    }

    return ids.map(v => {
        const device = data.devices.byId?.[v] ?? data.pois?.byId?.[v];

        const isInactive = deviceStatuses[v] !== 'active';
        const deviceType = device?.deviceType;

        if ((selectedDevice !== NO_DEVICE_SELECTED) && (v === selectedDevice) && device?.geoJSON) {
            device.geoJSON.properties.iconSize = SELECTED_ICON_SIZE;
        }
        else if ((device?.geoJSON)) {
            device.geoJSON.properties.iconSize = DEFAULT_ICON_SIZE;
        }

        if (!device?.isPoi && device?.geoJSON?.properties != null) {
            device.geoJSON.properties.riskColor = riskToColor(
                device.geoJSON.properties.risk,
                { deviceType, isInactive }
            );
        }

        if (device?.geoJSON?.properties) {
            if (device?.deviceType) {
                const iconNameTmp = `${device.deviceType}-icon`;
                const iconName = (iconNameTmp in MAP_IMAGES)
                    ? iconNameTmp
                    : 'default-icon';
                device.geoJSON.properties.icon = iconName;
            }
            else {
                device.geoJSON.properties.icon = 'default-icon';
            }

            device.geoJSON.properties.iconOpacity =
                ((NO_DEVICE_SELECTED === selectedDevice) || (v === selectedDevice))
                ? 1 : NOT_SELECTED_ICON_OPACITY;
        }

        if (!!device && device?.geoJSON?.geometry?.coordinates == null) {
            if (device.geoJSON == null) { device.geoJSON = {}; }
            if (device.geoJSON.geometry == null) { device.geoJSON.geometry = {}; }
            device.geoJSON.geometry.coordinates = [];
        }

        return device?.geoJSON
    }).filter((d) => !!d);
}

export function transformDeviceData(data, deviceStatuses) {
    let ids = data.devices.allIds;
    const deviceIds = Object.keys(deviceStatuses);

    if (deviceIds?.length > 0) {
        ids = deviceIds; // If deviceIds are provided, filter data to only
                         // use the provided ids.
    }
    if (data.pois?.allIds?.length > 0) {
        ids = [].concat(ids, data.pois.allIds)
    }

    const now_ = Date.now();

    return ids?.map(v => {
        const deviceInfo = data.devices.byId?.[v] ?? data.pois?.byId?.[v];

        if (!deviceInfo) {
            return {
                id: v,
                dataIsMissing: true
            }
        }

        const deviceData = deviceInfo?.data ?? {};

        const getLatestDataValue = getLastArrayValue;
        const getValue = (key) => {
            if (!!deviceInfo?.[key]) {
                return deviceInfo[key];
            }
            if (!!deviceInfo?.geoJSON?.properties?.[key]) {
                return deviceInfo.geoJSON.properties[key];
            }
            if (deviceData?.[key]?.length > 0) {
                return getLatestDataValue(deviceData[key]);
            }
            return undefined;
        }
        const retObj = (deviceInfo.isPoi) ? {
            ...deviceInfo,
            geoJSON: JSON.parse(JSON.stringify(deviceInfo.geoJSON)),
        } : {
            id: v,
            rosterId: deviceInfo.rosterId,
            nativeDeviceId: deviceInfo.macPretty,
            deviceType: deviceInfo.deviceType,
            battery: getValue('battery'),
            class: getValue('class'),
            confidence: getValue('confidence'),
            ctemp: getValue('ctemp'),
            stemp: getValue('stemp'),
            hr: getValue('hr'),
            hsi: getValue('hsi'),
            latitude: getValue('latlon')?.[0],
            longitude: getValue('latlon')?.[1],
            node: getValue('node'),
            risk: getValue('risk'),
            wobble: getValue('wobble'),
            no: getValue('no'),
            macPretty: deviceInfo.macPretty,
            geoJSON: JSON.parse(JSON.stringify(deviceInfo.geoJSON)),
            marineRisk: [deviceInfo.geoJSON.properties.marineRiskColor,
                getLatestDataValue(deviceData.risk)],
            data: deviceInfo.data,
            lastDataTimestamp: deviceInfo?.lastDataTimestamp,
            sinceLastSample: (deviceInfo?.lastDataTimestamp) ? (now_ - deviceInfo.lastDataTimestamp) : "",
            // deviceType: 'oban'
        };
        //console.log(retObj);
        const isInactive = deviceStatuses[v] !== 'active';
        const deviceType = retObj.deviceType;

        if (!deviceInfo.isPoi) {
            retObj.geoJSON.properties.riskColor = riskToColor(retObj.risk, { deviceType, isInactive });
        }

        return retObj;
    });
}

export function sanitizeIds(nativeIds) {
    function sanitizeAddress(nativeDeviceId) {
        return `${nativeDeviceId}`.replaceAll(":", "");
    }
    if (!(nativeIds instanceof Array)) {
        return Object.fromEntries(
            Object.entries(nativeIds ?? {})
                .map(([k, v]) => [sanitizeAddress(k), v])
        );
    }
    return (!!nativeIds?.length) ? nativeIds.map(sanitizeAddress) : undefined;
}

export function checkLastReceivedTimestamp(devices)
{
    if(devices?.length <= 0)
    {
        return [];
    }
    const now_ = Date.now();
    return Object.values(devices).map(dev => {
        //console.log(dev);
        dev.sinceLastSample = ((now_ - dev.lastDataTimestamp) > (dev.sinceLastSample + SINCE_LAST_UPDATE_BUFFER)) ? now_ - dev.lastDataTimestamp : dev.sinceLastSample;
        return dev;
    });
}
