import React from "react";

import QuickDashboard from "../misc/QuickDashboard";

import {
    faMapMarkedAlt,
    faChartArea,
    faTools,
    faClipboardList,
} from "@fortawesome/free-solid-svg-icons";

import PathList from "../../util/path-list";

export default class DashboardPortal extends React.Component {
    static defaultProps = {
        links: [
            {
                title: "Real-Time",
                destination: PathList.realTime,
                icon: faMapMarkedAlt
            },
            {
                title: "Real-Time Details",
                destination: PathList.realTimeDetails,
                icon: faClipboardList,
                hidden: false, // TODO add logic to show only for wound monitoring user.
            },
            {
                title: "Hot-Swap Devices",
                destination: PathList.realTimeStatus,
                icon: faClipboardList
            },
            {
                title: "Retrospective",
                destination: PathList.retrospective,
                icon: faChartArea
            },
            {
                title: "Configuration",
                destination: PathList.configuration,
                icon: faTools
            }
        ]
    }

    render() {
        return (
            <div>
                <div className="block"/>
                <QuickDashboard title="Dashboard" links={this.props.links} />
            </div>
        );
    }
}
