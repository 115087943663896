import DeviceDetailGraph from "./DeviceDetailGraph";

export default function NitricOxideGraph(props) {
    const data = props.data || [];
    const backgroundColor = props.backgroundColor || "rgba(0, 99, 132, 0.2)";
    const borderColor = props.borderColor || "rgba(0, 99, 132, 1)";
    const timeWidth = props.timeWidth;
    const showXLabels = props.showXLabels ?? false;

    return (
        <DeviceDetailGraph
            data={data}
            dataType={"no"}
            label={"NO"}
            backgroundColor={backgroundColor}
            borderColor={borderColor}
            timeWidth={timeWidth}
            showXLabels={showXLabels}
        />
    );
}
